import * as React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { Layout } from '../layouts/Layout'
import { SEO } from '../components/SEO'
import { ComponentCard } from '../components/ComponentCard'

const ContentWrapper = styled.div`
  max-width: 100%;
  margin: 50px 0;
  padding: 0 32px 64px;

  @media (min-width: 1024px) {
    max-width: 920px;
    margin: 50px auto;
    padding: 0 0 128px;
  }
`

const ComponentGroup = styled.div`
  & + & {
    margin-top: 50px;
  }
`

const ComponentsList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: -10px;

  > li {
    list-style-type: none;
    padding: 10px;
    width: 25%;
  }

  @media (max-width: 768px) {
    & > li {
      width: 33%;
    }
  }

  @media (max-width: 480px) {
    & > li {
      width: 50%;
    }
  }
`

type ComponentsListPageProps = {
  data: Data
}

export default function ComponentsListPage({
  data,
}: ComponentsListPageProps): React.ReactElement<ComponentsListPageProps> {
  const edges = data.allMdx.edges

  const groupByGroup: { [s: string]: { name: string; nodes: Node[] } } = {}

  edges.forEach(edge => {
    const group = edge.node.frontmatter.group || 'none'
    groupByGroup[group] = groupByGroup[group] || {
      name: group,
      nodes: [],
    }

    groupByGroup[group].nodes.push(edge.node)
  })

  return (
    <Layout>
      <SEO title="Components list" />
      <ContentWrapper>
        <h1>List of components</h1>
        {Object.entries(groupByGroup)
          .sort((a, b) => {
            return a[0].localeCompare(b[0])
          })
          .map(([_, { name, nodes }]) => (
            <ComponentGroup key={name}>
              <h2>{name}</h2>
              <ComponentsList>
                {nodes
                  .sort((a, b) => a.frontmatter.displayName.localeCompare(b.frontmatter.displayName))
                  .map(node => (
                    <li key={node.frontmatter.displayName}>
                      <ComponentCard
                        slug={node.fields.slug}
                        flag={node.frontmatter.flag}
                        name={node.frontmatter.displayName}
                        subgroup={node.frontmatter.subgroup}
                        disabled={node.frontmatter.disabled}
                      />
                    </li>
                  ))}
              </ComponentsList>
            </ComponentGroup>
          ))}
      </ContentWrapper>
    </Layout>
  )
}

export type Data = {
  allMdx: {
    edges: { node: Node }[]
  }
}

export type Node = {
  id: string
  frontmatter: { displayName: string; group?: string; subgroup?: string; flag?: string; disabled?: boolean }
  fields: { slug: string }
}

export const pageQuery = graphql`
  query componentsIndex {
    allMdx(filter: { frontmatter: { displayName: { ne: null } } }) {
      edges {
        node {
          id
          frontmatter {
            displayName
            group
            subgroup
            disabled
            flag
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
